function toggleAttribute({
  attr,
  event = 'click',
  value = true
}) {
  return this.on(event, (e) => {
    const $target = $(e.target);
    const currentValue = $target.attr(attr);
    setTimeout(() => $target.attr(attr, (currentValue === value ? false : value)), 1);
  });
}

$.fn.toggleAttribute = toggleAttribute;
