import jQuery from 'jquery';
import ClipboardJS from 'clipboard';
import expose from 'lib/expose';

expose({
  jQuery,
  $: jQuery,
  ClipboardJS
});

export default jQuery;