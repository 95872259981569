// Requires all files in this directory
import {isFalsy, isTruthy} from 'lib/is';
const req = require.context('./', true, /\.js$/);
req.keys().forEach(req);

// Bind Plugins
$(() => {
  // Adds 'slds-hide' if apex__dfc_user_info is FALSEY, but remove if when TRUTHY
  // <button data-cookie-show="apex__dfc_user_info">
  $('[data-cookie-show]').toggleByCookie({attr: 'data-cookie-show', condition: isFalsy});

  // Adds 'slds-hide' if apex__dfc_user_info is TRUTHY, but remove if when FALSEY
  // <button data-cookie-hide="apex__dfc_user_info">
  $('[data-cookie-hide]').toggleByCookie({attr: 'data-cookie-hide', condition: isTruthy});

  // jQuery AJAX always has CSRF token in header if available
  const token = $('meta[name="csrf-token"]').attr('content');

  $.ajaxSetup({
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', token);
    }
  });
});
