function setCookie({
  key,
  value,
  event = 'click',
  options = {path: '/'}
}) {
  return this.on(event, (e) => {
    const v = value || $(e.target).val();
    $.cookie(key, v, options);
  });
}

$.fn.setCookie = setCookie;
