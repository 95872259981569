function toggleByCookie({
  condition,
  attr = 'data-cookie-name',
  toggleClass = 'slds-hide',
}) {
  return this.each((i, element) => {
    const $target = $(element);
    const value = $.cookie($target.attr(attr));

    $target.toggleClass(toggleClass, condition(value));
  });
}

/**
 * This pluggin toggles a class based on a cookie value.
 *
 * options - Object:
 *   attr - String, name of attribute for cookie key (default: 'data-cookie-name').
 *   toggleClass - String, class to add/remove based on condition (default: 'slds-hide').
 *   condition - Function => Boolean, when the cookie value returns true toggleClass
 *               will be added, otherwise it will be removed.
 *
 * Examples
 *  // This bind will toggle 'slds-hide' based on the
 *  // value of it's 'data-cookie-name' attribute,
 *  // adding the class when falsy but removing if truthy
 *  $('[data-cookie-toggle]').toggleByCookie({condition: isFalsy});
 *
 *  // HTML
 *  // Adds 'slds-hide' if apex__dfc_user_info is falsey, but remove if when truthy
 *  // <button data-cookie-toggle data-cookie-name="apex__dfc_user_info">
 */
$.fn.toggleByCookie = toggleByCookie;
