// jQuery Dependencies
import 'lib/jQuery'; // code.jquery.com/jquery-1.12.4.min.js
import 'jquery-ujs'; // ruby on rails scripting adapter: https://github.com/rails/jquery-ujs
import 'jquery.cookie'; // cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.min.js

import expose from 'lib/expose';
import kebabCase from 'lodash/kebabCase';

// Load Custom Plugins
import 'plugins';

// Exposing dependencies shared with asset pipeline.
expose({
  kebabCase
});
